<template>
  <div class="page">
    <!-- 引入的返回头组件 -->
    <!-- <titlebar :name="name" /> -->
    <!-- 上方搜索栏,按钮 -->
    <div class="content">
      <!-- 会员列表 -->
      <!-- <div style="width: 25%"> -->
      <div class="vip_list">
        <div class="title">
          会员列表
          <div class="add" @click="jumpTo('add')">
            <van-icon name="add" size="2.1rem" class="mem-add-icon" color="#fff" />
            <span>新增</span>
          </div>
        </div>
        <div class="flex flex-jsb nowrap" style="background: #fff; padding: 2rem; ">
          <input v-model="content" ref="membercodeinput" class="menberinput" placeholder="请输入姓名/手机号"
            @keyup.enter="search" />
          <button class="menbersearch-btn" @click="search">搜索</button>
        </div>
        <div class="list">
          <div v-for="(item, i) in list" :key="i" :class="active == i ? 'vip_s bg-e7f3ff' : 'vip_s'"
            @click="change(item.id, i)">
            <img src="@/static/images/member-forbidden.png" class="sell-img" v-if="item.status == '0'" alt= />
            <div class="name">
              <div class="mem-nickname color-000 ft-bold" :class="item.status == '0' ? 'memberDisable' : ''">{{ item.name }}</div>
              <div class="ft-24 color-666" :class="item.status == '0' ? 'memberDisable' : ''">{{ item.phone }}</div>
            </div>
            <div class="money">
              <div class="color-333 ft-24 text-right" :class="item.status == '0' ? 'memberDisable' : ''">{{ item.member_type_name }}</div>
              <div class="ft-w-500" :class="item.status == '0' ? 'memberDisable' : ''">￥{{ item.balance || "0.00" }}</div>
            </div>
          </div>
        </div>
        <div class="pagination">
          <van-pagination class="pagination-foot" v-model="page" :total-items="total" :items-per-page="limit"
            @change="pageChange" force-ellipses mode="simple" />
        </div>
      </div>
      <!-- </div> -->
      <!-- 会员档案 -->
      <div class="xx">
        <!-- 会员资料 -->
        <div class="member-box">
          <div class="member-top-box">
            <!-- <div class="member-top-left-box">
              <div class="member_datum">
                <div class="text-left">{{ form.name }}</div>
                <div class="mg-tp20">{{ form.phone }}</div>
                <div class="ft-22 text-left mg-tp20">{{ form.member_type_name }}</div>
              </div>
              <div class="member_datum_foot">
                <div>
                  <span v-if="form.discount == null || form.discount == 10">无折扣</span>
                  <span v-else>享受商品{{ form.discount }}</span>
                </div>
              </div>
            </div> -->
            <div class="member-top-right-box">
              <div class="member-datainfo text-left pd-lt30" v-if="list.length != 0">
                <div class="text-left">{{ form.name }}</div>
                <div class="mg-tp20">{{ form.phone }}</div>
                <div class="ft-22 text-left mg-tp20">{{ form.member_type_name }}</div>
              </div>
              <div class="member-datainfo vertical-line" v-if="list.length != 0">
                <div class="mg-bt20 ft-24">余额</div>
                <div class="mg-bt25"><span class="ft-20">￥</span>{{ form.balance || '0' }}</div>
                <button class="recharge-btn" @click.stop="recharge()">充值</button>
              </div>
              <div class="member-datainfo vertical-line" v-if="list.length != 0">
                <div class="mg-bt20 ft-24">积分</div>
                <div class="mg-bt25">{{ form.score || '0' }}</div>
                <button class="recharge-btn" @click.stop="openCouponModal()">兑换</button>
              </div>
              <div class="member-datainfo vertical-line" v-if="list.length != 0">
                <div class="mg-bt20 ft-24">优惠券</div>
                <div class="mg-bt25 text-underline" @click.stop="jumpTo('coupon')">{{ coupon_num || '0' }}</div>
                <button class="recharge-btn" @click.stop="jumpTo('tosend')">发券</button>
              </div>
            </div>
          </div>
          <div class="member_consumption">
            <div class="member_cishu">
              <span class="span1">消费次数</span>
              <span class="span2">{{ form.order_num || '0' }}</span>
            </div>
            <div class="member_cishu">
              <span class="span1">消费金额</span>
              <span class="span2">{{ form.order_amount || '0.00' }}</span>
            </div>
            <div class="member_cishu">
              <span class="span1">充值次数</span>
              <span class="span2">{{ form.recharge_num || '0' }}</span>
            </div>
            <div class="member_cishu">
              <span class="span1">充值金额</span>
              <span class="span2">{{ form.recharge_amount || '0.00' }}</span>
            </div>
            <div class="member_cishu">
              <span class="span1">赠送金额</span>
              <span class="span2">{{ form.give_amount || '0.00' }}</span>
            </div>
          </div>
          <div class="member-info-box">
            <div class="member_data" v-if="form.ic_card_num && form.ic_card_num != ''">
              <div class="m-d-label">会员卡号：</div>
              <div class="member_data_txt">
                {{ form.ic_card_num || '' }}
              </div>
            </div>
            <div class="member_data">
              <div class="m-d-label">性别：</div>
              <div class="member_data_txt">
                {{ form.sex | SexFilter }}
              </div>
            </div>
            <div class="member_data">
              <div class="m-d-label">会员权益：</div>
              <div class="member_data_txt">
                <span v-if="form.member_price == 1">享会员价优惠</span>
                <span v-if="form.member_price == 2">{{ form.rate == null || form.rate >= 100 ? '无折扣' : '享' + form.rate_msg + '会员折扣' }}</span>
              </div>
            </div>
            <div class="member_data">
              <div class="m-d-label">会员生日：</div>
              <div class="member_data_txt">
                {{ form.birth || '' }}
              </div>
            </div>
            <div class="member_data" :class="{'noborder-member_data': form.ic_card_num && form.ic_card_num != '',}">
              <div class="m-d-label">注册时间：</div>
              <div class="member_data_txt">
                {{ form.create_time }}
              </div>
            </div>
            <!-- <div class="member_data">
              <div class="m-d-label">优惠券：</div>
              <div class="member_data_txt">
                {{ coupon_num }}
              </div>
              <div class="color-1588F5 text-underline mg-lt30" @click="jumpTo('coupon')">查看</div>
              <div class="color-1588F5 text-underline mg-lt30" @click="jumpTo('tosend')">发券</div>
            </div> -->
            <div class="member_data noborder-member_data">
              <div class="m-d-label">禁用会员：</div>
              <div class="member_data_txt">
                <van-switch v-model="form.status" active-value="0" inactive-value="1" size="3.5rem"
                  @change="handlestatus(form.status)" />
              </div>
            </div>
          </div>
          <div class="member_data">
            <div class="m-d-label">备注：</div>
            <div class="member_data_txt">
              {{ form.remark }}
              ghafgkhdafg
            </div>
          </div>
        </div>

        <div style="height: 9.5rem;"></div>

        <div class="flex flex-jc member_foot_button">
          <div class="flex">
            <div class="members_button bg-1588F5" @click="jumpTo('edit')" v-if="list.length != 0">
              <span>编 辑</span>
            </div>
            <!-- <div class="members_button bg-1588F5" @click="recharge()" v-if="list.length != 0">
              <span>充 值</span>
            </div> -->
            <div class="members_button bg-3DB956" @click="adjustment()" v-if="list.length != 0 && shop.user_model == 1">
              <span>调整余额</span>
            </div>
            <div class="members_button bg-FE724F" v-if="list.length != 0" @click="jumpTo('log')">
              <span>查看明细</span>
            </div>
            <div class="members_button bg-e60012" @click="deleteMember()" v-if="list.length != 0">
              <span>删除</span>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- 充值 -->
    <div class="recharge-modal" v-if="is_show_recharge">
      <div class="pplAmount">
        <img class="closeImg" src="@/static/images/close.png" @click="is_show_recharge = false;" />
        <div class="title">充值</div>
        <div class="line"></div>
        <div class="ft-28 bg-fff pd-tp10 pd-rt20 pd-bt10 mg-tp20 text-left" style="width: 84%;">
          <span class="ft-bold">选择充值金额：</span>
        </div>
        <div class="r-scheme-box">
          <div v-for="item in recharge_scheme_list" class="r-scheme-item" 
            @click="handleChooseRs(1, item)"
            :class="{ 'active-r-scheme-item': rechange_id == item.id, }"
          >
            <div>充{{ item.money }}元</div>
            <div v-if="item.type == 1" class="mg-tp10">赠送{{ item.give_money }}元</div>
            <div v-if="item.type == 2" class="mg-tp10">赠送优惠券×{{item.coupon_count}}</div>
          </div>
          <div class="r-scheme-item" :class="{ 'active-r-scheme-item': is_custom, }" @click="handleChooseRs(2)">自定义充值</div>
        </div>
        <div v-if="!is_custom && rechange_scheme_obj.type == 2" class="text-left ft-22 flex color-1588F5 mg-tp10" style="width: 84%; line-height: 1.4;">
          <div>赠送：</div>
          <div style="flex: 1;" class="flex">
            <div v-for="item in rechange_scheme_obj.coupon">【{{ item.name }}】{{ item.coupon_num }}张； </div>
          </div>
        </div>
        <div style="width: 84%" v-if="is_custom">
          <div class="flex flex-jsb mg-tp10">
            <div>
              <span class="ft-26">充值金额</span>
              <input :value="amount" readonly @click="isShowNumKeyboard = true;" />
            </div>
            <div>
              <span class="ft-26">赠送金额</span>
              <input :value="give_amount" readonly @click="isShowNumKeyboard1 = true;" />
            </div>
          </div>
        </div>
        <div class="ft-28 bg-fff pd-tp10 pd-rt20 pd-bt10 mg-tp20 text-left" style="width: 84%;">
          <span class="ft-bold">选择支付方式：</span>
        </div>
        <!-- 支付方式 -->
        <div class="payway-list">
          <div class="content-item flex flex-ac flex-jc" @click="choosePayWay(item.pay_index, item)"
            v-for="(item, index) in payWayList" :key="item.pay_index" :class="{
              'mg-rt70': index != payWayList.length - 1,
              'active-way': payTypeId == item.pay_index,
            }">
            <van-icon v-if="item.pay_index == 6" name="gold-coin" :color="payTypeId == item.pay_index ? '#fff' : '#1588f5'" size="4rem" />
            <van-icon v-if="item.pay_index == 7" name="scan" :color="payTypeId == item.pay_index ? '#fff' : '#1588f5'" size="4rem" />
            <div class="ft-28 mg-lt25">
              {{ item.payment }}
            </div>
          </div>
        </div>
        <div class="flex flex-ac" style="width: 84%; margin: 1.5rem 0 3rem 0;" v-if="showInput">
          <input placeholder="请在输入框光标闪烁时扫描付款码" ref="paycodeInput" v-model="paycode" autofocus
            class="paycode-input" @keyup.enter="pay" />
          <button class="clearcode-btn" @click="clearCode">清空</button>
        </div>
        <div class="width-full flex flex-ac mg-bt30 flex-jsb mem-paymoney" v-if="payTypeId == '6'">
          <div class="flex flex-ac" style="width: 49%;">
            <span>收款￥</span>
            <input class="paycode-input price-code-input" :value="collection" readonly
              @click="isShowNumKeyboard2 = true;" />
          </div>
          <div class="flex flex-ac" style="width: 49%;">
            <span>找零￥</span>
            <input type="Number" v-model="give_change" autofocus class="paycode-input price-code-input" disabled />
          </div>
        </div>
        <div class="line"></div>
        <div class="flex confirm">
          <div class="cancel" @click="pay_cancel">
            <p>取消</p>
          </div>
          <div class="ok" @click="pay">
            <p>手动确认</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 调整 -->
    <div class="pplAmount van-popup van-popup--center" v-show="is_show_change">
      <img class="closeImg" src="@/static/images/close.png" @click="is_show_change = false; isShowMask = false;" />
      <div class="title">调整</div>
      <div class="line"></div>
      <div class="flex flex-jsb" style="width: 80%; margin-top: 7rem;">
        <div>
          <span class="ft-26">当前余额</span>
          <input v-model="adjustform.remaining_sum" readonly="readonly" style="border: none;" />
        </div>
        <div>
          <span class="ft-26">调整余额</span>
          <input :value="adjustform.balance" readonly @click="isShowNumKeyboard3 = true;" />
        </div>
      </div>
      <div class="flex flex-ac" style="width: 80%; margin: 3rem 0 7rem 0;">
        <span class="ft-26">备注</span>
        <input v-model="adjustform.revise_remark" style="width: 70%; height: 5.2rem;" />
      </div>
      <div class="line"></div>
      <div class="flex confirm">
        <div class="cancel" @click="adjust_cancel">
          <p>取消</p>
        </div>
        <div class="ok" @click="adjust_submit">
          <p>确认</p>
        </div>
      </div>
    </div>
    <!-- 黑色蒙版 -->
    <van-overlay :show="isShowMask" />

    <popup-dialog v-if="showDialog" :title="dialogTitle" :data="dialogData" :message="dialogMsg"
      @closePopDialog="closePopDialog" @confirmPopDialog="confirmPopDialog"></popup-dialog>

    <!-- 充值金额 -->
    <van-number-keyboard v-model="amount" :title="amount" :show="isShowNumKeyboard" @blur="isShowNumKeyboard = false;" extra-key="." @input="amountChange" @delete="handleamountDelete" class="otherEatNum-nk" />
    <!-- 赠送金额 -->
    <van-number-keyboard v-model="give_amount" :title="give_amount" :show="isShowNumKeyboard1" @blur="isShowNumKeyboard1 = false;" extra-key="." class="otherEatNum-nk" />
    <!-- 收款金额 -->
    <van-number-keyboard v-model="collection" :title="collection" :show="isShowNumKeyboard2" @blur="collechange(); isShowNumKeyboard2 = false;" extra-key="." class="otherEatNum-nk" />
    <!-- 调整金额 -->
    <van-number-keyboard v-model="adjustform.balance" :title="adjustform.balance" :show="isShowNumKeyboard3" @blur="isShowNumKeyboard3 = false;" extra-key="." class="otherEatNum-nk" />
    
    <!-- 兑换优惠券 开始 -->
    <div class="choose-coupon-modal" v-if="show_exchange_modal">
      <div class="modal-content-box relative" style="z-index: 2002;">
        <img src="@/static/images/close.png" class="close absolute" alt srcset @click="closeExchangeModal" />

        <div class="wrapper">
          <div class="title color-333 ft-28 flex flex-ac flex-jc ft-bold' pd-bt25">积分兑换</div>

          <div class="mocontent">
            <div class="deskcontent">
              <div class="index_list" v-if="exchange_scheme_list.length > 0">
                <div v-for="(item, i) in exchange_scheme_list" :key="i" class="index_box relative" 
                  @click="chooseThatCoupon(item)"
                  :class="{
                    'disabled-coupon': !item.can_use
                  }"
                >
                  <van-checkbox v-if="item.can_use" class="del-checkbox" icon-size="2.6rem" :value="select_coupon.id == item.id" shape="square" />
                  <div class="exchange-score-tag">{{item.score || 0}}积分</div>
                  <div class="coupon-left">
                    <div class="color-1588F5 mg-bt15">
                      <span class="mg-rt5 ft-40 ft-bold">{{ item.coupon_obj.face }}</span>
                      <span class="ft-22" v-if="item.coupon_obj.type == 1" style="vertical-align: super;">元</span>
                      <span class="ft-22" v-if="item.coupon_obj.type == 2" style="vertical-align: super;">折</span>
                    </div>
                    <div class="ft-22 color-333">满{{ item.coupon_obj.cill }}可用</div>
                  </div>
                  <div style="flex: 1;" class="pd-lt20 pd-rt5">
                    <div class="ft-22 color-333 mg-bt25">{{ item.coupon_obj.name }}</div>
                    <div v-if="item.coupon_obj.period == 1" class="ft-20 color-666">领券后{{ item.coupon_obj.day }}天内有效</div>
                    <div v-if="item.coupon_obj.period == 2" class="ft-20 color-666">有效期：{{ item.coupon_obj.start_time }} 至 {{ item.coupon_obj.end_time }}</div>
                    <div v-if="item.coupon_obj.period == 3" class="ft-20 color-666">有效期：长期有效</div>
                  </div>
                </div>
              </div>
              <div class="mg-tp100" style="flex: 1;" v-if="exchange_scheme_list.length == 0">
                <div class="nullStyle">
                  <p class="text-center ft-24 color-999 mg-tp30">暂无数据~</p>
                </div>
              </div>
              <div class="flex mg-tp10 mg-bt10 mg-rt20" style="justify-content:flex-end;">
                <div class="pagination" v-if="m_count > 0">
                  <van-pagination v-model="m_page" :total-items="m_count" :items-per-page="m_limit" mode="simple" force-ellipses
                    @change="getExchangeSchemeList" style="background-color: #f2f2f2" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="height: 9rem;"></div>
        <div class="btns flex flex-jc">
          <button class="cancel" @click="closeExchangeModal">取消</button>
          <button class="confirm " @click="confirmExchange()">确认兑换</button>
        </div>
      </div>
    </div>
    <!-- 兑换优惠券 结束 -->
  </div>
</template>

<script>
import titlebar from "@/views/component/titlebar";
import { formatTime, getPayWayFromCode } from '../../utils/util';
import popupOfDialog from '@/views/component/popupOfDialog.vue';
import { initHCDScannerDecode, openBox } from '../../utils/outPrint';
import { mapGetters } from 'vuex';
export default {
  components: {
    titlebar: titlebar,
    'popup-dialog': popupOfDialog,
  },
  name: "vipManagement",
  data() {
    return {
      title: "",
      content: "",
      active: 0,
      list: [],
      form: {},
      show4: false,
      cz_num: 0,
      zx_num: 0,
      remark: '',
      index: 3,
      code: "",
      show5: false,
      amount: "",
      reason: "",
      page: 1,
      limit: 6,
      total: 0,
      modalType: '', // 弹窗类型
      setpage: 1,
      setlimit: 5,
      settotal: 0,
      rs_id: '', // 选择的充值金额设置
      loading: false,
      oldPaw: '', // 原密码
      pageParams: {},
      is_show_recharge: false, //充值弹窗
      isShowMask: false, //是否显示遮罩层
      payWayList: [
        { pay_index: "6", payment: "现金" },
        { pay_index: "7", payment: "扫码支付" },
      ], //支付方式
      payTypeId: "",
      showInput: false,
      paycode: "", // 付款码
      is_show_change: false, //调整弹窗
      amount: "",
      give_amount: "",
      isClick: false,
      collection: '', //收款
      isShowNumKeyboard: false,
      isShowNumKeyboard1: false,
      isShowNumKeyboard2: false,
      isShowNumKeyboard3: false,
      amountVals: [],
      adjustform: {
        type: "", //会员类型
        remaining_sum: "", //当前余额 只做展示
        balance: "", //调整余额
        revise_remark: "", //调整备注
      },
      showDialog: false,
      dialogType: "",
      dialogTitle: "",
      dialogMsg: "",
      dialogData: {},
      coupon_num: 0,
      show_exchange_modal: false,
      select_coupon: {},
      choosed_coupon: {},
      recharge_scheme_list: [], // 充值有礼方案
      is_custom: false, // 是否自定义充值
      rechange_id: '', // 选择的充值方案
      rechange_scheme_obj: {},
      exchange_scheme_list: [], // 积分兑换方案
      m_page: 1, // 当前页数
      m_limit: 6,
      m_count: 0,
    };
  },
  computed: {
    ...mapGetters({ hcd_barcode: 'hcd_barcode', shop: 'shop' }),
    // 找零
    give_change() {
      if (this.collection && this.collection > this.amount) {
        let price = Number(this.collection - this.amount).toFixed(2);
        return price;
      }
      return "0.00";
    },
    platform_name() {
      return localStorage.nowDevice;
    },
  },
  watch: {
    hcd_barcode: {
      handler(newV, oldV) {
        console.log('this.hcd_barcode', this.hcd_barcode);
        if (this.hcd_barcode.code == 1) {
          console.log('this.showInput', this.showInput);
          console.log('this.showInputRecharge', this.showInputRecharge);
          if (this.showInput) {
            this.paycode = this.hcd_barcode.data;
            console.log('this.paycode', this.paycode);
            this.$forceUpdate();
          }
        }
      },
      deep: true,
    },
  },
  filters: {
    SexFilter(val) {
      const obj = {
        1: '男',
        2: '女'
      }
      return obj[val] || '';
    }
  },
  created() {
    this.$nextTick(() => {
      this.$refs.membercodeinput.focus();
      this.$refs.membercodeinput.setAttribute("readonly", "readonly");
      setTimeout(() => {
        this.$refs.membercodeinput.removeAttribute("readonly");
      }, 200);
    }, 200);
    this.name = this.$route.query.name || ""; //拿到传过来的标题
    // this.pageParams = this.$route.params || {};
    this.active = this.$route.params.active || 0;
    this.page = this.$route.params.page || 1;
    this.content = this.$route.params.content || '';
    this.maxdate = new Date();
    this.getlist();
    // this.getRechargeSet();
    this.$store.commit('updateHcdBarcode', '');
    this.getRechargeSchemeList();
  },
  methods: {
    getExchangeSchemeList() {
      return new Promise((resolve, reject) => {
        const params = {
          page: this.m_page,
          limit: this.m_limit,
          // sort: 1,
          status: 1,
        }
        this.$api.getPointsExchange(params).then(res => {
          if (res.code == 1) {
            this.exchange_scheme_list = res.data.list || [];
            this.m_count = res.data.count || 0;
            this.exchange_scheme_list.forEach(element => {
              let coupon_obj = element.coupon[0];
              if (coupon_obj.period == 2) {
                const s_time = new Date(coupon_obj.start_time).getTime();
                const e_time = new Date(coupon_obj.end_time).getTime();
                const now_time = new Date().getTime();
                if (this.form.score * 1 >= element.score * 1 && now_time <= e_time ) {
                  element['can_use'] = true;
                } else {
                  element['can_use'] = false;
                }
              } else {
                element['can_use'] = this.form.score * 1 >= element.score * 1;
              }
              if (coupon_obj.type == 1) {
                coupon_obj.face = coupon_obj.face / 100;
              } else if (coupon_obj.type == 2) {
                coupon_obj.face = coupon_obj.face / 10;
              }
              coupon_obj.cill = coupon_obj.cill / 100;
              if (coupon_obj.period == 2) {
                coupon_obj.start_time = formatTime(new Date(coupon_obj.start_time), 'yyyy-MM-dd');
                coupon_obj.end_time = formatTime(new Date(coupon_obj.end_time), 'yyyy-MM-dd');
              }
              element['coupon_obj'] = coupon_obj;
            });
            resolve();
          } else {
            this.exchange_scheme_list = [];
            this.m_count = 0;
            reject();
          }
        })
      })
    },
    handleChooseRs(type, item) {
      if (type == 2) {
        this.is_custom = true;
        this.rechange_id = '';
        this.rechange_scheme_obj = {};
        this.amount = '';
        this.give_amount = '';
        this.collection = this.amount;
      } else {
        this.is_custom = false;
        this.rechange_id = item.id;
        this.rechange_scheme_obj = item;
        this.amount = item.money;
        this.give_amount = item.type == 1 ? item.give_money : '0';
        this.collection = this.amount;
      }
    },
    getRechargeSchemeList() {
      const params = {
        sort: 1,
      }
      this.$api.getRechangeActivity(params).then(res => {
        if (res.code == 1) {
          this.recharge_scheme_list = res.data.list || [];
        } else {
          this.recharge_scheme_list = [];
        }
      })
    },
    chooseThatCoupon(item) {
      if (!item.can_use) {
        return;
      }
      this.select_coupon = item;
    },
    async confirmExchange() {
      if (!Object.keys(this.select_coupon).length) {
        this.$toast('请选择要兑换的礼品');
        return;
      }
      this.$api.userExchange({
        pid: this.select_coupon.id,
        member_id: this.form.id,
      }).then(res=> {
        if (res.code == 1) {
          this.$toast('兑换成功');
          this.closeExchangeModal();
          this.getMemberInfo(this.form.id);
          this.getCouponNum(this.form.id);
        } else {
          this.$toast(res.msg);
        }
      })
    },
    closeExchangeModal() {
      this.show_exchange_modal = false;
    },
    async openCouponModal() {
      this.m_page = 1;
      await this.getExchangeSchemeList();
      this.show_exchange_modal = true;
    },
    getCouponNum(id) {
      this.$api.Coupon_memberCoupon({
        page: 1,
        limit: 10,
        type: 0,
        uid: id,
      }).then(res => {
        if (res.code == 1) {
          this.coupon_num = res.data.count || 0;
        } else {
          this.coupon_num = 0;
        }
      })
    },
    // 现金支付打开钱箱
    openDevieBox() {
      if (this.platform_name == 'android' || this.platform_name == 'mt_android') {
        console.log('------');
        openBox();
      }
    },
    closePopDialog() {
      this.showDialog = false;
    },
    confirmPopDialog(data) {
      let query = {
        member_id: this.form.id,
      }
      this.$api.dt_delMember(query).then(res => {
        if (res.code == 1) {
          this.$toast(res.msg || '删除成功');
          this.active = 0;
          this.content = '';
          this.form = {};
          this.page = 1;
          this.old_page = 1;
          this.choose_page = 1;
          this.getlist();
          this.closePopDialog();
        } else {
          this.$toast(res.msg || '删除失败');
          this.closePopDialog();
        }
      })
    },
    deleteMember() {
      this.dialogTitle = '删除会员';
      this.dialogMsg = `确认删除此会员吗？`;
      this.showDialog = true;
    },
    // 调整提交
    adjust_submit() {
      if (this.adjustform.balance < 0) {
        this.$toast("调整余额不能为负数！");
        return;
      } else if (this.adjustform.balance == "") {
        this.$toast("调整余额不能为空！");
        return;
      }
      if (!/^(([0-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/.test(this.adjustform.balance)) {
        this.$toast("调整余额必须为数字，小数点后最多两位");
        return;
      }
      let params = {
        id: this.form.id,
        type: this.adjustform.type,
        remaining_sum: this.adjustform.remaining_sum,
        balance: this.adjustform.balance > 0 ? this.adjustform.balance : '0.00',
        revise_remark: this.adjustform.revise_remark,
      }
      this.$api.dt_editMember(params).then((res) => {
        if (res.code == 1) {
          this.$toast("修改成功！");
          // 左侧会员列表余额显示修改
          this.list[this.active].balance = this.adjustform.balance;
          // 会员详情余额修改
          this.form.balance = this.adjustform.balance;
          this.is_show_change = false;
          this.isShowMask = false;
          this.adjustform.remaining_sum = this.adjustform.balance;
          this.adjustform.balance = "";
        } else {
          this.$toast(res.msg);
        }
      });
    },
    adjust_cancel() {
      this.is_show_change = false;
      this.isShowMask = false;
      this.adjustform.balance = "";
      this.adjustform.revise_remark = "";
    },
    // 调整按钮
    adjustment() {
      this.adjustform.revise_remark = "";
      if (this.form.balance) {
        this.adjustform.remaining_sum = this.form.balance;
      } else {
        this.adjustform.remaining_sum = 0;
      }
      this.adjustform.type = this.form.type;
      this.is_show_change = true;
      this.isShowMask = true;
    },
    // 支付
    pay() {
      if (this.amount == '') {
        this.$toast('充值金额不能为空');
        return
      }
      if (this.amount < 0 || this.give_amount < 0) {
        this.$toast("充值金额不能为负数！");
        return;
      }
      if (!/^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/.test(this.amount)) {
        this.$toast("充值金额必须为数字，小数点后最多两位");
        return;
      }
      if (this.give_amount && this.give_amount != '' && this.give_amount > 0 && !/^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/.test(this.give_amount)) {
        this.$toast("赠送金额必须为数字，小数点后最多两位");
        return;
      }
      if (!this.payTypeId || this.payTypeId == '') {
        this.$toast('请选择支付方式');
        return
      }
      if (this.isClick) {
        return
      }
      if (this.payTypeId == 1 || this.payTypeId == 2 || this.payTypeId == 7 || this.payTypeId == 4) {
        // 微信、支付宝
        this.offlinePay();
        return;
      }
      this.moneyPay();
    },
    // 扫描支付码支付
    offlinePay() {
      if (this.paycode == "") {
        this.$toast("请扫描付款码进行结账");
        this.$nextTick(() => {
          this.$refs.paycodeInput.focus();
          this.$refs.paycodeInput.setAttribute("readonly", "readonly");
          setTimeout(() => {
            this.$refs.paycodeInput.removeAttribute("readonly");
          }, 200);
        }, 100);
        return;
      }
      const pay_type = getPayWayFromCode(this.paycode);
      this.isClick = true;
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      let params = {
        id: this.form.id,
        amount: this.amount,
        give_amount: this.give_amount || '0',
        pay_type: pay_type,
        pay_bar_code: this.paycode,
        line_type: 0,
        rechange_id: this.rechange_id || '',
      }
      this.tj_cz(params);
    },
    // 现金确认
    moneyPay() {
      this.isClick = true;
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      let params = {
        id: this.form.id,
        amount: this.amount,
        give_amount: this.give_amount || '0',
        pay_type: this.payTypeId,
        line_type: 0,
        rechange_id: this.rechange_id || '',
      }
      this.tj_cz(params);
    },
    // 充值取消
    pay_cancel() {
      this.is_show_recharge = false;
      this.showInput = false;
      this.amount = "";
      this.give_amount = "";
      this.paycode = "";
    },
    choosePayWay(id, item) {
      this.payTypeId = id;
      this.pay_type_text = item.payment;
      this.showInput = false;
      if (this.payTypeId == 1 || this.payTypeId == 2 || this.payTypeId == 7 || this.payTypeId == 4) {
        this.showInput = true;
        if (localStorage.nowDevice == 'android') {
          initHCDScannerDecode('starthcdscan');
        }
        this.$nextTick(() => {
          this.$refs.paycodeInput.focus();
          this.$refs.paycodeInput.setAttribute("readonly", "readonly");
          setTimeout(() => {
            this.$refs.paycodeInput.removeAttribute("readonly");
          }, 200);
        }, 100);
      } else if (this.payTypeId == 6) {
        this.collection = this.amount;
      }
    },
    clearCode() {
      this.paycode = "";
      if (localStorage.nowDevice == 'android') {
        initHCDScannerDecode('starthcdscan');
      }
      this.$nextTick(() => {
        this.$refs.paycodeInput.focus();
        this.$refs.paycodeInput.setAttribute("readonly", "readonly");
        setTimeout(() => {
          this.$refs.paycodeInput.removeAttribute("readonly");
        }, 200);
      }, 100);
    },
    // 充值
    recharge() {
      this.rechange_id = '';
      this.rechange_scheme_obj = {};
      this.payTypeId = "";
      this.is_show_recharge = true;
    },
    collechange() {
      if (Number(this.collection) < Number(this.amount)) {
        this.$toast("收款金额小于待收金额！");
        this.collection = this.amount;
      }
    },
    amountChange(e) {
      this.amountVals.push(e);
      this.collection = this.amountVals.join('');
    },
    handleamountDelete(e) {
      this.amountVals.pop();
      this.collection = this.amountVals.join('');
    },
    // 更改会员状态
    handlestatus(status) {
      let params = {
        status,
        member_id: this.form.id
      }
      this.$api.dt_editStatus(params).then((res) => {
        if (res.code == 1) {
          this.list[this.active].status = status;
          this.$toast("修改成功！");
        }
      });
    },
    // 页码改变
    pageChange(e) {
      this.page = e;
      console.log("会员列表，当前页码：", this.page);
      this.getlist();
    },
    chongzhi(test) {
      this.index = test;
    },
    tj_cz(params) {
      console.log(params);
      this.$api.dt_memberRecharge(params).then((res) => {
        this.$toast.clear();
        this.$toast(res.msg);
        if (res.code == 1) {
          if (params.pay_type == 6) {
            this.openDevieBox();
          }
          this.is_show_recharge = false;
          this.getMemberInfo(this.list[this.active].id);
          this.rechargePrinterPrint(res.data, params.pay_type);
          let mem_info = this.form;
          mem_info.balance = res.data.data.after_amount;
          this.$set(this.list, this.active, mem_info);
          this.getCouponNum(this.list[this.active].id);
        }
        this.isClick = false;
      }).catch(() => {
        this.$toast.clear();
        this.isClick = false;
      });
    },
    rechargePrinterPrint(data, pay_type) {
      console.log(data);
      if (data.recharge_print == 1) {
        const p_data = data.data || {};
        let result = {
          type: 3,
          shopName: p_data.shop_name || '',
          shop_admin_name: p_data.casher_name || '',
          recharge_time: p_data.recharge_time || '',
          recharge_no: p_data.recharge_num || '',
          member_name: p_data.member_name || '',
          member_phone: p_data.member_phone || '',
          amount: p_data.amount || '',
          give_amount: p_data.give_amount || '',
          recharge_before: p_data.before_amount || '',
          recharge_after: p_data.after_amount || '',
          pay_type_text: this.getPayTypeText(pay_type),
          shop_address: p_data.address || '',
          shop_phone: p_data.shop_phone || '',
        }
        this.$printFunc.printReceipt(result);
      }
    },
    getPayTypeText(pay_type) {
      const obj = {
        1: '微信',
        2: '支付宝',
        4: '云闪付',
        6: '现金',
      }
      return obj[pay_type] || '';
    },
    search() {
      if (this.content != '') {
        const code_cont = this.content.replace(/[|]/g, "|")
          .replace(/[丨]/g, "|")
          .replace(/[｜]/g, "|");
        console.log('code_cont', code_cont);
        let cont_arr = code_cont.split('|');
        console.log(cont_arr[0], cont_arr[1], parseInt((new Date().getTime() - 30000) / 1000));
        if (cont_arr[1] > 0 && cont_arr[1] < parseInt((new Date().getTime() - 30000) / 1000)) {
          this.content = '';
          this.$toast('会员码已过期，请重新打开小程序出示会员码');
          return;
        }
        this.content = cont_arr[0];
      }
      this.active = 0;
      this.form = {};
      this.page = 1;
      this.old_page = 1;
      this.choose_page = 1;
      this.getlist();
    },
    jumpTo(type) {
      if (type == 'add') {
        this.$router.push({
          name: 'addMember',
          params: { active: '' },
          query: {
            name: 'addMember'
          }
        })
      } else if (type == 'edit') {
        this.$router.push({
          name: 'addMember',
          params: {
            type: 'memberlist'
          },
          query: {
            name: 'editMember',
            id: this.form.id,
            page: this.page,
            content: this.content,
            active: this.active,
          }
        })
      } else if (type == 'log') {
        this.$router.push({
          name: 'memberrecord',
          query: {
            name: 'memberrecord',
            id: this.form.id,
            page: this.page,
            content: this.content,
            active: this.active,
          },
        })
      } else if (type == 'coupon') {
        this.$router.push({
          name: 'member-coupon',
          query: {
            name: 'member-coupon',
            id: this.form.id,
            page: this.page,
            content: this.content,
            active: this.active,
          }
        })
      } else if (type == 'tosend') {
        let obj = {
          data: this.form,
          tab_id: 2,
        }
        sessionStorage.setItem('memobj', JSON.stringify(obj));
        this.$router.push({
          name: 'coupon-manage',
          query: {
            name: 'coupon-manage',
            active: 13,
            pre: 'vip',
          }
        })
      }
    },
    getlist() {
      let query = {
        page: this.page,
        limit: this.limit
      }
      if (this.content && this.content != '') {
        query.content = this.content;
      }
      this.$api.dt_getMemberList(query).then((res) => {
        if (res.code == "1") {
          this.list = res.data.data.list || [];
          this.list.forEach((element) => {
            if (element.member_type) {
              let a = element.member_type.split("--");
              element.member_type_name = a[0];
            }
          });
          this.total = res.data.data.count || 0;
          if (this.list.length > 0) {
            this.getMemberInfo(this.list[this.active].id);
            this.getCouponNum(this.list[this.active].id);
          }
        }
      });
    },
    change(id, i) {
      if (this.active != i) {
        this.active = i;
        this.getMemberInfo(id);
        this.getCouponNum(id);
      }
    },
    getMemberInfo(id) {
      this.$api.dt_getMemberList({ id: id }).then((res) => {
        if (res.code == 1) {
          this.form = res.data.data.list[0];
          if (this.form.member_type) {
              let a = this.form.member_type.split("--");
              this.form.member_type_name = a[0];
              this.form.discount = a[1];
            }
        } else {
          this.$toast(res.msg);
        }
      });
    },
    // 禁止键盘弹起
    noKeybord(e) {
      e.target.setAttribute("readonly", true);
      setTimeout(() => {
        e.target.removeAttribute("readonly");
      }, 100);
    },
  },
};
</script>

<style lang="less" scoped>
.r-scheme-box {
  width: 84%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 2rem;
  max-height: 20vh;
  overflow-y: scroll;
  height: auto;
  .r-scheme-item {
    min-width: 15rem;
    font-size: 2.2rem;
    background: #f2f2f2;
    color: #666;
    height: 8.5rem;
    padding: 0 2rem;
    margin: 0 2rem 1.5rem 0;
    text-align: left;
    border-radius: 0.8rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .active-r-scheme-item {
    background: #1588F5;
    color: #fff;
  }
}
::-webkit-scrollbar {
  display: none;
}
.otherEatNum-nk {
  border-radius: 0.8rem;
  width: 30vw;
  left: 35vw;
  z-index: 9999;
}
.van-overlay {
  z-index: 1000;
}

.recharge-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1010;
  display: flex;
  align-items: center;
  justify-content: center;

  .pplAmount {
    width: 100rem;
		max-height: 88vh;
		height: fit-content;
		background-color: #fff;
  }
}
.pplAmount {
  z-index: 1999;
  position: fixed;
  width: 82rem;
  background: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 0.8rem;

  .title {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    font-size: 2.8rem;
    font-weight: bold;
    color: rgba(51, 51, 51, 1);
  }

  .line {
    width: 84%;
    height: 1px;
    background: rgba(204, 204, 204, 1);
  }

  input {
    width: 13.5rem;
    height: 4.7rem;
    border: 0.1rem solid #DEDEDE;
    font-size: 2.6rem;
    color: rgba(153, 153, 153, 1);
    border-radius: 0.8rem;
    margin-left: 1.5rem;
    padding-left: 1rem;
  }

  .paycode-input {
    border: 0.2rem rgba(0, 0, 0, 0.06) solid;
    padding: 0.5rem 1rem;
    flex: 1;
    border-radius: 0.8rem;
    font-size: 2.4rem;
  }

  .clearcode-btn {
    background: #1588f5;
    color: #fff;
    font-size: 2.4rem;
    width: 11rem;
    height: 5.7rem;
    border-radius: 0.5rem;
    margin-left: 2rem;
  }

  .closeImg {
    width: 2.6rem;
    height: 2.6rem;
    position: absolute;
    right: 2.5rem;
    top: 2.5rem;
  }

  .payway-list {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3rem;
    margin-top: 2rem;
    width: 84%;

    .content-item {
      color: #333;
      width: calc((100% - 8rem) / 2);
      height: 11rem;
      border-radius: 0.8rem;
      background: #f2f2f2;
      position: relative;
    }

    .active-way {
      color: #fff;
      background: #1588f5;
    }
  }

  .mem-paymoney {
    font-size: 2.6rem;
    width: 84%;
    .price-code-input {
      width: 84%;
      text-align: left;
    }
  }

  .confirm {
    padding: 4.5rem 0 3rem;
    .cancel,
    .ok {
      width: 20rem;
      height: 6.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0.8rem;
    }

    .cancel {
      margin-right: 10rem;
      background: #eaeaea;

      p {
        font-size: 2.4rem;
        color: #666;
      }
    }

    .ok {
      background: #1588f5;

      p {
        font-size: 2.4rem;
        color: rgba(255, 255, 255, 1);
      }
    }
  }
}
.pagination {
  height: 6rem;
  position: relative;

  .pagination-foot {
    width: 100%;
    position: absolute;
    bottom: 0;
  }
}

.page {
  width: 100%;
  height: 100%;
  background: #f0f2f5;
  display: flex;
  flex-direction: column;

  .content {
    display: flex;
    width: 100%;
    height: calc(100vh - 10.3rem);
    background: #f0f2f5;

    .vip_list {
      background: white;
      // border: 0.2rem solid rgba(187, 187, 187, 100);
      width: 25%;
      display: flex;
      flex-direction: column;

      .menberinput {
        background: #f2f2f2;
        border-radius: 0.8rem;
        width: calc(100% - 11.5rem);
        height: 5.1rem;
        font-size: 2.2rem;
        padding-left: 2rem;
        // flex: 1;
      }

      .menbersearch-btn {
        background: #3880ff;
        font-size: 2.2rem;
        color: #fff;
        height: 5.1rem;
        width: 8rem;
        border-radius: 0.8rem;
        margin-left: 1.5rem;
      }

      .title {
        text-align: left;
        line-height: 7.5rem;
        border-bottom: 0.2rem solid #f5f5f5;
        color: #101010;
        font-size: 3rem;
        font-weight: 700;
        padding-left: 2rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 2rem;

        .add {
          width: 11.5rem;
          background: #3880ff;
          color: white;
          height: 5.2rem;
          font-size: 2.1rem;
          border-radius: 0.8rem;
          display: flex;
          align-items: center;
          justify-content: center;

          .mem-add-icon {
            margin-right: 1rem;
          }
        }
      }

      .list {
        overflow-y: scroll;
        // height: calc(100% - 15.2rem);
        flex: 1;
        padding: 0 2rem;

        &::-webkit-scrollbar {
          width: 0;
        }

        .vip_s:first-child {
          border-top: 0.2rem solid #f5f5f5;
        }

        .vip_s {
          height: 12rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 1.5rem;
          border-bottom: 0.2rem solid #f5f5f5;
          position: relative;

          .sell-img {
            width: 5rem;
            height: 4.5rem;
            position: absolute;
            left: 0;
            top: 0;
          }

          .memberDisable {
            color: #999999 !important;
          }

          .name {
            // line-height: 6.5rem;
            color: #101010;
            font-size: 2.6rem;
            text-align: left;
          }

          .mem-nickname {
            margin-bottom: 1.5rem;
          }

          .money {
            //color: #3880ff;
            font-size: 2.6rem;
            font-weight: 500;

            div:last-child {
              margin-top: 1.5rem;
              color: #3880ff;
            }
          }

          .jingyong {
            /* width: 0; */
            position: absolute;
            top: 0;
            border-top: 6rem solid red;
            border-left: 8rem solid transparent;
            right: 0;
          }

          .text {
            position: absolute;
            top: 0.4rem;
            right: 0;
            font-size: 2.4rem;
            color: white;
          }
        }
      }
    }

    ::-webkit-scrollbar {
      display: none;
    }

    .xx {
      width: calc(75% - 4rem);
      background: #ffffff;
      height: 100%;
      margin-left: 2rem;
      // border: 0.2rem solid rgba(187, 187, 187, 100);
      position: relative;
      padding: 2rem;
      overflow-y: scroll;


      .member-box {
        .member_datum {
          // background: #333;
          border-radius: 0.8rem 0.8rem 0 0;
          // background-image: url("../../static/images/member_back.png");
          // background-repeat: no-repeat;
          // background-size: 100% auto;
          height: 16.57rem;
          color: #fff;
          font-size: 3.2rem;
          display: flex;
          flex-direction: column;
          padding: 0 4rem 0 3.2rem;
          justify-content: center;
          text-align: left;
        }

        .member_datum_foot {
          // background: rgba(82, 81, 82);
          border-radius: 0 0 0.8rem 0.8rem;
          height: 5.3rem;
          display: flex;
          // justify-content: flex-end;
          align-items: center;
          font-size: 2.2rem;
          color: #ffffff;
          padding: 0 3rem 0 3.2rem;
        }

        .member_consumption {
          margin: 2rem 0;
          display: flex;
          justify-content: space-between;

          .member_cishu {
            width: 23.7rem;
            height: 7rem;
            background: #f6f6f8;
            border-radius: 0.8rem;
            display: flex;
            justify-content: center;
            align-items: center;

            .span1 {
              font-size: 2.4rem;
              color: #666666;
            }

            .span2 {
              font-weight: 600;
              font-size: 2.6rem;
              color: #000000;
              margin-left: 1rem;
            }
          }
        }

        .member_data {
          height: 8.5rem;
          border-bottom: 0.2rem #f5f5f5 solid;
          font-size: 2.4rem;
          color: #666666;
          display: flex;
          // justify-content: space-between;
          align-items: center;

          .m-d-label {
            width: 16rem;
            text-align: left;
          }

          .member_data_txt {
            font-size: 2.4rem;
            color: rgba(0, 0, 0, 0.85);
          }
        }
        .noborder-member_data {
          border-bottom: 0;
        }

        .member-info-box {
          display: grid;
          grid-template-columns: repeat(2, 50%);
          border-bottom: 0.2rem #f5f5f5 solid;
        }

        .member-top-box {
          display: flex;
          align-items: center;
          height: 21.87rem;
          background-image: url("../../static/images/member_back.png");
          background-repeat: no-repeat;
          background-size: 100% 100%;

          .member-top-left-box {
            width: 33%;
            // margin-right: 2.5rem;
          }
          .member-top-right-box {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;
            // background: #333;
            height: 100%;
            // border-radius: 0.8rem;
            border-radius: 0 0.8rem 0.8rem 0;
            font-size: 3rem;
            color: #fff;
            .member-datainfo {
              flex: 1;
              position: relative;

              .recharge-btn {
                width: 9.5rem;
                height: 4.3rem;
                border-radius: 0.8rem;
                color: #333;
                font-size: 2.4rem;
                background: #fff;
              }
            }

            .vertical-line:before {
              content: '';
              width: 0.2rem;
              height: 50%;
              position: absolute;
              left: 0;
              top: 25%;
              background: #fff;
            }
          }
        }
      }

      .member_foot_button {
        width: 69%;
        position: fixed;
        bottom: 1.5rem;
        right: 0;

        .members_button {
          width: 15rem;
          height: 5.5rem;
          border-radius: 0.8rem;
          color: #fff;
          font-size: 2.4rem;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 4.5rem;
        }
      }
    }

    .consumption {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      font-size: 3rem;
      line-height: 7.5rem;
      border-bottom: 0.2rem solid #f5f5f5;
    }
  }

  .popup {
    width: 64rem;
    max-height: 86rem;
    min-height: 80rem;
    border-radius: 3rem;
    // text-align: center;
    border: 0.2rem solid rgba(187, 187, 187, 100);

    .h1 {
      line-height: 8rem;
      color: #101010;
      font-size: 3rem;
      text-align: left;
      font-weight: 500;
      padding-left: 2rem;
      border-bottom: 0.2rem solid #e8eaec;
    }

    .box {
      display: flex;
      // justify-content: space-around;
      float: right;
      margin-top: 3rem;

      .button1 {
        width: 10rem;
        height: 5rem;
        line-height: 5rem;
        border-radius: 1rem;
        background-color: #3880ff;
        color: #ffffff;
        font-size: 2.3rem;
        text-align: center;
        border: 0.2rem solid #f5f5f5;
        margin: 0 2rem 0 9rem;
      }

      .button2 {
        width: 9.6rem;
        line-height: 4.6rem;
        height: 4.6rem;
        border-radius: 1rem;
        // background-color: #3880ff;
        // color: #ffffff;
        font-size: 2.3rem;
        text-align: center;
        border: 0.2rem solid #f5f5f5;
      }
    }
  }
}

/deep/.van-search {
  padding: 0;
}

/deep/.van-search__content {
  background: white;
  border-bottom: 0.2rem solid #f5f5f5;
}

/deep/.van-cell {
  margin: 1rem;
}

/deep/.van-field__label {
  width: 11rem;
  line-height: 4rem;
  font-size: 2.4rem;
  color: black;
}

/deep/.van-cell__value {
  font-size: 2.4rem;
  width: calc(100% - 15rem);
  text-align: left;
  // height: ;
  line-height: 4rem;
  border-radius: 0.7rem;
  color: rgba(136, 136, 136, 100);
  border: 0.2rem solid #f2f2f2;
  padding-left: 1rem;
}

/deep/.van-field__body {
  line-height: 4rem;
}

/deep/.van-cell--required::before {
  line-height: 4rem;
}

/deep/.van-cell__title {
  text-align: left;
  width: 15rem;
  line-height: 4rem;
  font-size: 2.4rem;
  margin-right: 1rem;
  flex: inherit;
  color: black;
}

/deep/.van-cell__right-icon {
  position: absolute;
  top: 1.8rem;
  right: 2.2rem;
}

/deep/.switch .van-cell__value {
  border: 0rem;
}

.popup1 {
  width: 64rem;
  // height: 54rem;
  // min-height: 80rem;
  border-radius: 3rem;
  // text-align: center;
  border: 0.2rem solid rgba(187, 187, 187, 100);

  .h1 {
    line-height: 8rem;
    color: #101010;
    font-size: 3rem;
    text-align: left;
    font-weight: 500;
    padding-left: 2rem;
    border-bottom: 0.2rem solid #e8eaec;
  }

  .cz {
    display: flex;
  }

  .h2 {
    color: rgba(16, 16, 16, 100);
    font-size: 2.4rem;
    text-align: left;
    font-weight: 800;
    margin-left: 2.5rem;
  }

  .fs {
    display: flex;
    justify-content: space-around;
    padding-top: 2rem;
    padding-bottom: 2rem;

    .icon {
      display: flex;
      flex-direction: column-reverse;
      font-size: 2rem;
      line-height: 4rem;
      width: 7rem;
      border-radius: 2rem;
      padding: 1.5rem;
      border: 0.2rem solid rgba(187, 187, 187, 100);
    }
  }

  .code {
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-bottom: 0.2rem solid #e8eaec;
    padding-bottom: 2rem;

    .button {
      width: 10rem;
      height: 5rem;
      line-height: 5rem;
      border-radius: 1rem;
      background-color: red;
      color: #ffffff;
      font-size: 2.3rem;
      text-align: center;
      // border: 0.2rem solid #f5f5f5;
      // margin: 0 2rem 0 9rem;
    }

    .code-input {
      font-size: 2.4rem;
      //width: calc(100% - 12rem);
      text-align: left;
      // height: ;
      line-height: 4rem;
      border-radius: 0.7rem;
      color: rgba(136, 136, 136, 100);
      border: 0.2rem solid #f2f2f2;
      padding-left: 1rem;
    }
  }

  .box {
    display: flex;
    // justify-content: space-around;
    float: right;
    margin-top: 1.5rem;

    .button1 {
      width: 10rem;
      height: 5rem;
      line-height: 5rem;
      border-radius: 1rem;
      background-color: #3880ff;
      color: #ffffff;
      font-size: 2.3rem;
      text-align: center;
      border: 0.2rem solid #f5f5f5;
      margin: 0 2rem 0 9rem;
    }

    .button2 {
      width: 9.6rem;
      line-height: 4.6rem;
      height: 4.6rem;
      border-radius: 1rem;
      // background-color: #3880ff;
      // color: #ffffff;
      font-size: 2.3rem;
      text-align: center;
      border: 0.2rem solid #f5f5f5;
    }
  }
}

/deep/.popup .van-overlay {
  background-color: rgba(0, 0, 0, .3);
}

.search {
  width: 8rem;
  line-height: 4.5rem;
  background: #3880ff;
  color: white;
  font-size: 2.1rem;
  border-radius: 1.5rem;
  text-align: center;
}

.choose-coupon-modal {
  text-align: left;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
	
	.modal-content-box {
		width: 66%;
		max-height: 88vh;
		height: fit-content;
		border-radius: 0.8rem;
		background-color: #fff;
	}
	.close {
		width: 2.6rem;
    height: 2.6rem;
		position: absolute;
    right: 2.5rem;
    top: 2.5rem;
		z-index: 10;
	}
	.wrapper {
		padding: 0rem 3.5rem;
		position: relative;
	
		.title {
			padding-top: 2.5rem;
			border-bottom: 0.2rem solid rgba(204, 204, 204, 1);
		}

    .mocontent {
      margin: 2rem 0 1rem;
      position: relative;
      display: flex;
      justify-content: center;
      overflow-y: scroll;
      height: 58vh;
      .deskcontent {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;

        .del-checkbox {
          position: absolute;
          left: 0;
          top: 0;
          background: #fff;
        }

        .exchange-score-tag {
          position: absolute;
          top: 0;
          right: 0;
          background: #1588F5;
          color: #fff;
          font-size: 2rem;
          padding: 0.7rem;
          border-radius: 0 0.8rem 0 0.8rem;
        }
      }
    }
	}
	.btns {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 8.5rem;
		.confirm,
		.cancel {
			color: #fff;
			min-width: 20rem;
			height: 6.5rem;
			border-radius: 0.8rem;
			font-size: 2.4rem;
			padding: 0 2.5rem;
		}
		.cancel {
			background: #EAEAEA;
			color: #666666;
			margin-right: 10rem;
		}
		.confirm {
			background: #1588f5;
		}
	}
  .pagination {
    width: 30%;
    /deep/.van-pagination {
      font-size: 1.9rem;
    }
    /deep/.van-pagination__item--active {
      background-color: #1588F5;
    }
  }
}
.index_list {
  width: calc(100% - 4rem);
  // height: 50rem;
  flex: 1;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: grid;
  grid-template-columns: repeat(2, 48%);
  grid-template-rows: repeat(auto-fill, 15rem);
  grid-gap: 2.5rem 3%;
  overflow-x: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 0;
  }

  .disabled-coupon {
    opacity: 0.5;
  }

  .index_box {
    position: relative;
    // width: 32.9rem;
    // height: 16rem;
    background: #ffffff;
    // box-shadow: 0rem 0.4rem 2rem 0rem rgba(153, 153, 153, 0.2);
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    position: relative;
    padding: 0.5rem 1.5rem;
    height: 15rem;
    border: 0.2rem #cdcdcd solid;
    border-radius: 0.8rem;

    .coupon-left {
      width: 23%;
      position: relative;
    }
    .coupon-left::after {
      content: '';
      width: 0.15rem;
      height: 132%;
      position: absolute;
      right: 0;
      top: -15%;
      border-right: 0.15rem dashed #E5E5E5;
    }
  }
}
</style>
